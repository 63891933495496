.toolbar {
  .nav-pills {
    >li {
      >a {
        border: 0px;
      }
    }
  }

  .nav {
    .nav-item {
      margin: 2px;

      .nav-link {
        border-radius: 4px;
        color: $default-states-color;
        cursor: pointer;
      }

      .active {
        border-radius: 4px;
        color: $white-color  !important;
        background-color: $primary-color;
      }

      .active:hover,
      .active:focus {
        background-color: $primary-states-color  !important;
      }

      .nav-link:hover,
      .nav-link:focus {
        color: $white-color;
        background-color: $default-states-color;
      }
    }
  }
}