.dropdown-item {
    font-size: .9rem;
  }
  
  .z-0 {
    z-index: 0;
  }
  
  .filter-body-100{
    width: 100px;
  }
  .filter-body-200{
    width: 200px;
  }
  .filter-body-300{
    width: 300px;
  }
  .filter-body-400{
    width: 400px;
  }
  .filter-body-500{
    width: 500px;
  }
  .filter-body-600{
    width: 600px;
  }
  .filter-body-700{
    width:700px;
  }