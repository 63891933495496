@import "../variables";
@import "tags";

@mixin select($type, $color) {
  .select-#{$type}.el-select .el-input {
    .el-input__suffix {
      display: flex;
      align-items: center;
    }

    &:hover {

      .el-input__icon,
      // input {
      //   &::placeholder{
      //     color: white;
      //   }
      //   color: white;
      // }
      input,
      .el-input__icon:not(.el-select__caret) {
        background-color: $color;
      }

    }

    input {
      background-color: white;

      @if($color ==$default-color) {
        border-color: $medium-gray  !important;
      }

      @else {
        border-color: $color  !important;
      }

      border-width: 1px;
      color: $color;
    }

    .el-input__icon {
      color: $color;
    }
  }

  .select-#{$type} .el-select__tags {
    .el-tag {
      border-radius: 3px !important;

      .el-tag__close {
        background-color: transparent;
        color: $color;
        opacity: 1;
        font-size: 12px;
      }

      .el-tag__close:hover {
        background-color: transparent;
        color: $color;
        font-weight: bold;
        font-size: 18px;
        transition: font-size .2s ease-out;
      }

      border-color: $color;
      background-color: white;
      border-radius: 2px;
      color: $color;
    }
  }

  .el-select-dropdown.is-multiple,
  .el-select-dropdown__item.selected {
    &.select-#{$type} {
      color: $color  !important;
    }
  }
}

.el-select .el-input {
  &:hover {

    // .el-input__icon,
    // input {
    //   &::placeholder {
    //     color: white;
    //   }

    //   color: white;
    // }
  }
}

.el-select-dropdown {
  border-radius: 10px;

}

@include select('default', $default-color);
@include select('info', $info-color);
@include select('primary', $primary-color);
@include select('success', $success-color);
@include select('warning', $warning-color);
@include select('danger', $danger-color);