@mixin estado-styles($estado-color, $text-color) {
  background: $estado-color;
  color: $text-color;
  border-color: $estado-color;
  border-style: solid;
  border-width: 1px;
}

.estado-click:hover {
  @include estado-styles($default-color, #e9ecef);
  cursor: pointer;
}

.estado {
  border-radius: 25px;
  font-size: small;
  font-weight: bold;
  @include estado-styles($default-color, $white-color);
  padding: 0px 15px 0px 15px;
  min-width: 180px;
}

.estado-solicitado {
  @include estado-styles($default-color, $white-color);
}

.estado-error {
  @include estado-styles(#FF0000, $white-color)
}

.estado-caducado {
  @include estado-styles(#FF0000, $white-color)
}


.estado-no_valido {
  @include estado-styles(#fb6d00, $white-color)
}

.estado-finalizado {
  @include estado-styles(#104794, $white-color);
}

.estado-pendiente_recogida {
  @include estado-styles(#A9A4F0, $white-color);
}

.estado-recogida_finalizada {
  @include estado-styles(#104794, $white-color);
}

.estado-en_transito {
  @include estado-styles(#ED7D31, $white-color);
}

.estado-entrega_pendiente {
  @include estado-styles(#00B0F0, $white-color);
}

.estado-en_reparto {
  @include estado-styles(#92D050, $white-color);
}

.estado-entregado {
  @include estado-styles(#00B050, $white-color);
}

.estado-no_localizado {
  @include estado-styles($danger-color, $white-color);
}

.estado-entrega_anulada {
  @include estado-styles($danger-color, $white-color);
}

.estado-envio_anulado {
  @include estado-styles($danger-color, $white-color);
}

.estado-incidencia {
  @include estado-styles($danger-color, $white-color);
}

.estado-normalizada {
  @include estado-styles(#104794, $white-color);
}

.estado-en_curso {
  @include estado-styles(#00B050, $white-color);
}

.estado-comunicando {
  @include estado-styles(#3573fc, $white-color);
}