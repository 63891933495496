





























































.notificaciones-card {
  min-height: 464px;
  max-height: 464px;
  padding-top: 20px;
  overflow: auto;
}
