


















































.el-icon-close{
  cursor: pointer;
}
