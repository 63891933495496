






























































































































































.login-button {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
}
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
.header-login {
  display: grid;
  > div {
    width: 100%;
  }

  justify-content: center;
}
